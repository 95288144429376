import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Row} from "react-bootstrap";

import {hover} from "../styles/globalStyleVars";
import {CSSPlugin, gsap, TimelineLite} from "gsap";
import {BsChevronRight} from "react-icons/bs";
import {Link, useLocation, NavLink} from "react-router-dom";
import {FaAngleRight, FaChevronDown, FaChevronRight} from "react-icons/fa";
import Button from "./Button";
import {apiEndPoints} from "../api/network/apiEndPoints";
import {fetchBusiness} from "../api/redux/Business";
import {useDispatch, useSelector} from "react-redux";


const MainMenu = () => {
    const location = useLocation();
    gsap.registerPlugin(CSSPlugin);
    const tl = new TimelineLite()
    const mobileMenuRaf = useRef();
    const menuRef = useRef();
    const hamburgerRef = useRef();
    const hamburgerCloseRef = useRef();
    const containerRef = useRef();

    const dispath = useDispatch()


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.DOMAIN
        dispath(fetchBusiness([api_url]))
    }, [])
    let getPost = useSelector(state => state.business)
    let domain = getPost?.posts?.domain_list;

    //---- on scroll menu fixed action
    useEffect(() => {
        if (document.body.classList.contains("scroll-down")) {
            document.body.classList.remove("scroll-down");
        }
    });

    useEffect(() => {
        const body = document.body;
        const scrollUp = "scroll-up";
        const scrollDown = "scroll-down";
        let lastScroll = 0;
        let howMuchScroll = 150;
        if (window.screen.width < 991) {
            howMuchScroll = 80;
        } else {
            howMuchScroll = 150;
        }

        window.addEventListener("scroll", () => {
            const tl = new TimelineLite()
            let currentScroll = window.pageYOffset;

            if (currentScroll <= 0) {
                body.classList.remove(scrollUp);
                return;
            }
            if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
                // down
                body.classList.remove(scrollUp);

                body.classList.add(scrollDown);
                tl.to('.contact-button__content', {
                    duration: .4,
                    height: 0,
                    opacity: 0,
                    padding: '0 30px'
                }).to('.contact-button__content', {
                    duration: 0,
                    display: 'none',

                })
                document.querySelector('.contact-button .db-btn')?.classList.remove('menu-slide');
            } else if (
                currentScroll < lastScroll &&
                body.classList.contains(scrollDown)
            ) {
                // up
                body.classList.remove(scrollDown);
                body.classList.add(scrollUp);
            }
            lastScroll = currentScroll;
        });
    }, []);


    // desktop contact action
    useEffect(() => {
        document.querySelector('.contact-button .dc-btn').addEventListener('click', function () {

            if (!this.classList.contains('menu-slide')) {
                tl.to('.contact-button__content', {
                    duration: 0,
                    display: 'block',
                    padding: '30px'
                }).to('.contact-button__content', {
                    duration: .5,
                    height: 'auto',
                    opacity: 1
                })
                this.classList.add('menu-slide')
            } else {
                tl.to('.contact-button__content', {
                    duration: .4,
                    height: 0,
                    opacity: 0,
                    padding: '0 30px'
                }).to('.contact-button__content', {
                    duration: 0,
                    display: 'none',

                })
                this.classList.remove('menu-slide')
            }

            window.addEventListener('click', (e) => {
                if (document.querySelector('.menu-slide')) {
                    if (!e.target.matches('.contact-button .dc-btn,.contact-button, .contact-button .dc-btn a, .contact-button span,.contact-button svg,.contact-button__content,.contact-button__content div, .contact-button__content h4,.contact-button__content p')) {
                        tl.to('.contact-button__content', {
                            duration: .4,
                            height: 0,
                            opacity: 0,
                            padding: '0 30px'
                        }).to('.contact-button__content', {
                            duration: 0,
                            display: 'none',

                        })
                        this.classList.remove('menu-slide')
                    }
                }
            })


        });
    }, [])


    useEffect(() => {
        // menu click on mobile

        function calculateHeight() {
            let vh = window.innerHeight * 0.01;
            document?.documentElement?.style.setProperty('--vh', `${vh}px`);
            let menuCloseAction = document?.querySelector('.mobile-menu__items');
            if (menuCloseAction) {
                menuCloseAction.style.height = `${vh * 100}px`;
            }
        }

        window.addEventListener('resize', calculateHeight);            //to stop scrolling after clicking the menu button

        hamburgerRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.add("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.add("menu-visible");
            document.body.classList.add("menu-showed");
            calculateHeight()
            gsap.to(menuRef.current, {
                display: "block",
            });
            gsap.to(menuRef.current, {
                opacity: 1,
                duration: ".3",
            });
        });

        hamburgerCloseRef.current.addEventListener("click", () => {
            document.querySelector(".hamburger").classList.remove("menu-open");
            document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
            // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
            document.body.classList.remove('contact-slided')
            document.body.classList.remove("menu-showed");
            gsap.to(menuRef.current, {
                opacity: 0,
                duration: ".2",
            });
            gsap.to(menuRef.current, {
                display: "none",
            });
        });

        let getLinkTag = document.querySelectorAll("a");

        for (let i of getLinkTag) {
            i.addEventListener("click", () => {
                document.querySelector(".hamburger").classList.remove("menu-open");
                document.querySelector(".Mobile-menu-wrap").classList.remove("menu-visible");
                // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
                document.body.classList.remove('contact-slided')
                document.body.classList.remove("menu-showed");
                gsap.to(menuRef.current, {
                    opacity: 0,
                    duration: ".2",
                });
                gsap.to(
                    menuRef.current,
                    {
                        display: "none",
                    },
                    "-=.2"
                );

                gsap.to('.desktop-menu__bottom ul li ul', {
                    opacity: 0,
                    visibility: 'hidden',
                    top: '70px',
                    duration: .2,
                    zIndex: -2
                })
                setTimeout(() => {
                    let getAllSub = document.querySelectorAll('.desktop-menu__bottom ul li ul');
                    getAllSub.forEach(function (e) {
                        e.removeAttribute('style')
                    })

                }, 800)

            });
        }

    }, [domain]);

    // mobile contact slide


    useEffect(() => {
        document.querySelector('.mobile-menu__items__contact').addEventListener('click', function () {
            // document.querySelector('.mobile-menu').classList.add('menu-slide-right')
            // document.querySelector('.Mobile-menu-wrap').classList.add('contact-slided')
            document.body.classList.add('contact-slided')
        });

        document.querySelector('.contact-info__top p').addEventListener('click', function () {
            // document.querySelector('.Mobile-menu-wrap').classList.remove('contact-slided')
            document.body.classList.remove('contact-slided')
        });
    }, [])


    // active menu
    const aboutClass = location.pathname.match(/^\/about-infratech/) ? "active" : "";
    const contactClass = location.pathname.match(/^\/contact/) ? "active" : "";


    return (
        <>
            <StyledDesktopMenu className="desktop-menu ">
                <div className={'desktop-bg'}>
                    <Container ref={containerRef} >
                        <Row className="desktop-menu__top">
                            <Col className="desktop-menu__top__logo">
                                <Link to={"/"}>
                                    <img
                                        alt="logo"
                                        src={'/images/static/logo-white.svg'}
                                    />
                                </Link>
                            </Col>

                            <Col className="d-flex justify-content-end">
                                <div className="contact-button">
                                    <Button hoverBorderColor={'rgb(25, 97, 172)'} background={'transparent'}
                                            border={'1px solid #FFF'}
                                            hoverBackground={'rgb(25, 97, 172)'}
                                            width={'170px'}
                                            text={'Contact'}/>

                                    <div className="contact-button__content">
                                        <div className="contact-button__content__top">
                                            <Link to={'/contact'}>Contact page <span><FaChevronRight/></span></Link>
                                        </div>
                                        <h4>Email</h4>
                                        <a style={{marginBottom: '30px'}}
                                           href="mailto:info@infraconsultants.com">info@infraconsultants.com</a>

                                        <h4>Visit</h4>
                                        <a href="https://maps.app.goo.gl/JDoxvtfGvEqNPqmAA" target="_blank">
                                            Infratech Consultants Ltd.<br/>House - 83 Suhrawardy Avenue | Baridhara Diplomatic Zone
                                            | Dhaka -1212, Bangladesh</a>
                                    </div>

                                </div>

                            </Col>

                        </Row>

                        <div className="desktop-menu__bottom">
                        <ul>
                                <li className={location.pathname === '/' ? 'active' : ''}>
                                    <Link to={"/"}>
                                        <span>Home</span>
                                    </Link>
                                </li>
                                <li className={`has-child ${location.pathname === '/about-infratech' ? 'active' : ''}`}>
                                    <Link to={"/about-infratech"}>
                                        <span>About</span>
                                    </Link>
                                    <FaChevronDown/>
                                    <ul>
                                        <li className={location.pathname === '/about-infratech' ? 'active' : ''}>
                                            <Link to={"/about-infratech"}>
                                                Infratech Consultants <BsChevronRight/> </Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <Link activeClassName={'active'}*/}
                                        {/*          to={"/about-infratech#management-team"}> Management*/}
                                        {/*        Team <BsChevronRight/>*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        <li>
                                            <Link
                                                to={"/about-infratech#partners"}> Partners <BsChevronRight/> </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={"/about-infratech#customers"}>Customers <BsChevronRight/></Link>
                                        </li>
                                        <li>
                                            <Link to={"/about-infratech#licenses-memberships"}>Licenses &
                                                Memberships <BsChevronRight/></Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={"/about-infratech#accolades"}>Accolades <BsChevronRight/></Link>
                                        </li>
                                        {/*<li>*/}
                                        {/*    <Link*/}
                                        {/*        to={"/social-responsibility-and-esg"}>Social Responsibility &*/}
                                        {/*        ESG<BsChevronRight/></Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                </li>
                                <li className={`has-child ${location.pathname === '/business-domains' ? 'active' : ''}`}>
                                    <Link to={"/business-domains"}>
                                        <span>Business Domains </span>
                                    </Link>
                                    <FaChevronDown/>

                                    <ul>
                                        {
                                            domain && domain.length > 0 &&
                                            domain.map((element, index) => {
                                                return (
                                                    <li key={index}>
                                                        <Link
                                                            to={`/business-domains#${element?.post_name}`}>{element?.post_title}<BsChevronRight/>
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                        {/*<li>*/}
                                        {/*    <Link to={"/business-domains"}>Power & Energy<BsChevronRight/> </Link>*/}
                                        {/*</li>*/}
                                        {/*<li>*/}
                                        {/*    <Link to={"/business-domains"}>*/}

                                        {/*        Transportation Technology <BsChevronRight/>*/}

                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                    </ul>
                                </li>


                                {/*<li className={location.pathname === '/projects' ? 'active' : ''}>*/}
                                {/*    <Link to={"/projects"}>*/}
                                {/*        <span>Projects</span>*/}
                                {/*    </Link>*/}
                                {/*</li>*/}

                                {/*<li className={location.pathname === '/media-center' ? 'active' : ''}>*/}
                                {/*    <Link to={"/media-center"}>*/}

                                {/*        <span>Media Center</span>*/}

                                {/*    </Link>*/}
                                {/*</li>*/}

                                <li className={location.pathname === '/career' ? 'active' : ''}>
                                    <Link to={"/career"}>

                                        <span>Career</span>

                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Container>

                </div>

            </StyledDesktopMenu>

            {/*mobile menu */}
            <StyledMobileMenu className="Mobile-menu-wrap menu-bar">
                <Container>
                    <Row className="mobile-menu" ref={mobileMenuRaf}>
                        <Col className="logo">
                            <Link to={"/"}>

                                <img src={'/images/static/logo-white.svg'}/>
                                <img src={'/images/static/logo-white.svg'}/>

                            </Link>
                        </Col>

                        <Col className="hamburger">
                          <span ref={hamburgerRef}>
                            <img src={'/images/static/hamburger.svg'}/>
                          </span>

                            <span ref={hamburgerCloseRef}>
                            <img src={'/images/static/close-btn.svg'}/>
                          </span>
                        </Col>

                        <div className="mobile-menu__items" ref={menuRef}>

                            {/*<Button text={'Contact'} src={'#'} margin={'0 0 60px 0'}/>*/}

                            <div className="mobile-menu__items__contact">
                                <p>Contact</p>
                            </div>


                            <ul className="mobile-menu__items__ul">
                                <Accordion defaultActiveKey="">
                                    <li className={location.pathname === '/' ? 'active' : ''}>
                                        <Link to={"/"}>
                                            Home
                                        </Link>

                                    </li>

                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header
                                            className={`has-child ${location.pathname === '/about-infratech' ? 'active' : ''}`}>
                                            About Us <img src={'/images/static/down.svg'}/>{" "}
                                            <img src={'/images/static/up.svg'}/>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <ul>
                                                <li>
                                                    <Link to={"/about-infratech"}> Infratech Consultants
                                                    </Link>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <Link to={"/about-infratech#management-team"}> Management Team*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                                <li>
                                                    <Link to={"/about-infratech#partners"}> Partners </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/about-infratech#customers"}>Customers </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/about-infratech#licenses-memberships"}>Licenses &
                                                        Memberships </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/about-infratech#accolades"}>Accolades </Link>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <Link*/}
                                                {/*        to={"/social-responsibility-and-esg"}>Social Responsibility &*/}
                                                {/*        ESG</Link>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header
                                            className={`has-child ${location.pathname === '/business-domains' ? 'active' : ''}`}>
                                            Business Domains <img src={'/images/static/down.svg'}/>{" "}
                                            <img src={'/images/static/up.svg'}/>
                                        </Accordion.Header>
                                        <Accordion.Body>

                                            <ul>
                                                {
                                                    domain && domain.length > 0 &&
                                                    domain.map((element, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <Link
                                                                    to={`/business-domains#${element?.post_name}`}>{element?.post_title}<BsChevronRight/>
                                                                </Link>
                                                            </li>
                                                        )
                                                    })
                                                }

                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    {/*<li className={location.pathname === '/projects' ? 'active' : ''}>*/}
                                    {/*    <Link to={"/projects"}>Projects</Link>*/}
                                    {/*</li>*/}


                                    {/*<li className={location.pathname === '/media-center' ? 'active' : ''}>*/}
                                    {/*    <Link to={"/media-center"}>*/}
                                    {/*        Media Center*/}
                                    {/*    </Link>*/}
                                    {/*</li>*/}

                                    <li className={location.pathname === '/career' ? 'active' : ''}>
                                        <Link to={"/career"}>
                                            Career
                                        </Link>
                                    </li>
                                </Accordion>
                            </ul>
                        </div>

                        <div className="contact-info">

                            <div className="contact-info__top">
                                <p><img src="/images/static/arrow-blue.svg" alt=""/>Contact</p>
                                <Link to={'/contact'}><span>Contact Page</span> <img
                                    src="/images/static/right.svg"
                                    alt=""/></Link>
                            </div>


                            <div className="contact-info__desc">
                                <div className="contact-info__desc__single">
                                    <h4>Email</h4>
                                    <a href="mailto:info@infraconsultants.com">info@infraconsultants.com</a>
                                </div>

                                <div className="contact-info__desc__single">
                                    <h4>Visit</h4>
                                    <a href="https://www.google.com/maps/place/NDE+Infratech+Ltd./@23.7884183,90.416811,15z/data=!4m6!3m5!1s0x3755c79fa1407d9f:0x8c92e477abb92c13!8m2!3d23.7884183!4d90.416811!16s%2Fg%2F11kj3nwvzb">
                                        Grand Delvistaa | Level 7 | Plot 1A | Road 113 <br/>
                                        Gulshan Avenue | Dhaka-1212 | Bangladesh
                                        {/*Grand Delvistaa <br/>*/}
                                        {/*Level 7, Plot 1A <br/>*/}
                                        {/*Road 113, Gulshan Avenue <br/>*/}
                                        {/*Dhaka 1212,*/}
                                    </a>
                                </div>

                            </div>

                        </div>
                    </Row>
                </Container>
            </StyledMobileMenu>
        </>
    );
};

const StyledDesktopMenu = styled.section`
  position: fixed;
  z-index: 9999;
  width: 100%;
  //background: rgba(255, 255, 255, 0.9);
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  //background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.92) 3%) ;

  transition: all 0.4s ease;
  //
  .desktop-bg{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.92) 3%) ;
  }

  .desktop-menu__top {
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 99;
    position: relative;

    &__logo {
      a {
        display: flex;
      }

      img {
        height: 45px;
      }
    }

    ul {
      li {
        //width: 101px;
        //height: 35px;
        background-color: #221f1f;
        color: #ffffff;
        margin-right: 30px;
        border-radius: 19px;
        display: flex;
        align-items: center;
        //padding: 0px 20px 0 35px;
        cursor: pointer;
        height: 35px;
        align-items: center;
        justify-content: center;

        &:nth-of-type(1) {
          width: 101px;
        }

        &:nth-of-type(2) {
          width: 117px;
        }

        &:nth-last-child(1) {
          margin-right: 0;
          background-color: ${hover};
          width: 101px;

          &.hover:after {
            background-color: #221f1f;
          }
        }

        a {
          display: flex;
          align-items: center;
          text-transform: capitalize;
          height: 100%;
          width: 100%;
          justify-content: center;
          z-index: 1;
        }

        img {
          z-index: 2;
        }

        span {
          margin-left: 20px;
          color: #ffffff;
          font-size: 12px;
          line-height: 18px;
          font-weight: 600;
        }

        &:nth-last-of-type(1) {
          a {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
          }
        }
      }
    }

    .contact-button {
      position: relative;

      &__content {
        width: 270px;
        background-color: #FFFFFF;
        position: absolute;
        right: 0;
        padding: 30px;
        top: 70px;
        display: none;
        height: 0;
        overflow: hidden;
        opacity: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

        &__top {
          a {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            position: relative;
            width: 100%;
            display: flex;
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 1px solid #707070;

            span {
              position: absolute;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              border: 1px solid #404040;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 0;
              top: 2px;

              svg {
                color: #404040;
                font-size: 10px;
              }
            }
          }


        }

        h4 {
          font-size: 12px;
          line-height: 24px;
          color: rgba(59, 56, 56, 0.50);
        }

        a {
          font-size: 16px;
          line-height: 24px;
          color: #3b3838;
          display: block;
          font-weight: 400;
        }

        p {
          font-weight: 500;
          line-height: 24px;
          font-size: 16px;
        }
      }
    }
  }

  //bottom menu
  .desktop-menu__bottom {
    height: 50px;

    ul {
      display: flex;
      width: 100%;
      height: 100%;

      li {
        display: flex;
        flex: 1;
        margin-right: 30px;
        border-top: 1px solid rgba(255, 255, 255, 0.5);
        font-size: 12px;
        font-weight: 600;
        //padding-top: 13px;
        cursor: pointer;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          height: 1px;
          width: 0;
          left: 0;
          top: -1px;

          background-color: #FFF;
          transition: width 0.6s cubic-bezier(0.87, 0, 0.13, 1);
        }


        span {
          width: 100%;
          position: relative;
          z-index: 2;
          padding-top: 13px;
          color: #ffffff;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }

        > svg {
          color: #FFF;
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0px;
          font-size: 17px;
          line-height: 24px;
          margin: auto;
        }

        a {
          font-size: 14px;
          font-weight: 400;
          display: flex;
          width: 100%;
          line-height: 24px;
          color: #ffffff;
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }

        ul {
          position: absolute;
          flex-wrap: wrap;
          top: 70px;
          background: #061524;
          width: 319px;
          z-index: 9;
          padding: 15px 0 15px 0;
          opacity: 0;
          height: auto;
          display: block;
          visibility: hidden;
          transition: all .5s ease;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);

          li {
            display: block;
            min-width: 100%;
            border: none;
            padding: 0 30px 0 30px;
            //opacity: 0;

            :last-child {
              a {
                &:after {
                  display: none;
                }
              }
            }

            a {
              display: flex;
              padding: 15px 0 15px 0;
              //border-bottom: 1px solid rgba(34, 31, 31, 0.1);
              color: #FFFFFF;
              width: 100%;
              margin-bottom: 0;
              position: relative;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;

              svg {
                position: absolute;
                right: 30px;
                color: #FFFFFF;
                opacity: 0;
                transition: all 0.3s ease;
                top: 0;
                bottom: 0;
                margin: auto;

              }

              &:after {
                content: '';
                position: absolute;
                height: 1px;
                background-color: rgba(255, 255, 255, 0.5) !important;
                left: 0px;
                right: 0px;
                bottom: 0;
              }

              &:hover {
                color: #ffffff !important;
              }
            }

            &:after {
              display: none !important;
            }


            &:nth-last-child(1) {
              a {
                margin-bottom: 0;
                border: none;
                padding-bottom: 15px;
              }
            }

            &:hover {
              svg {
                right: 0;
                opacity: 1;
              }
            }

            ul {
              display: block !important;
              height: auto !important;
              position: absolute;
              background-color: #F2F2F2;
              width: 270px;
              z-index: 2;
              left: calc(100% + 0px);
              top: -5px;
              opacity: 0;
              transition: opacity 0.3s ease;

              &:before {
                content: none;
              }

              li {
                opacity: 1 !important;
              }
            }

            &:hover {
              ul {
                opacity: 1 !important;
              }
            }
          }
        }

        &.active {
          &:after {
            width: 100%;
          }
        }

        &:hover {
          &:after {
            width: 100%;
          }

          ul {
            opacity: 1;
            visibility: visible;
            top: 48px;

          }
        }


        // &.active > a {
          //   color: ${hover};
        // }
        //
        // &.has-child.active > span {
          //   color: ${hover};
        // }

      }
    }


  }

  //search

  @media (max-width: 992px) {
    display: none;
  }

  @media (max-width: 1100px) {
    .desktop-menu__bottom ul li span {
      font-size: 13px;
    }
  }

`;

const StyledMobileMenu = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 9999;
  background-color: transparent;
  display: flex;
  align-items: center;
  overflow: visible !important;
  //box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  //display: none;
  transition: all 0.3s ease;

  .dc-btn {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.5);
    left: 15px;
    right: 15px;
    bottom: 0;
  }


  .mobile-menu {
    .logo {
      display: flex;
      align-items: center;

      img {
        height: 35px !important;

        &:nth-last-of-type(1) {
          display: none;
        }
      }
    }

    .hamburger {
      position: relative;
      display: flex;
      align-items: center;
      //justify-content: flex-end;

      span {
        position: absolute !important;
        right: 15px !important;
        //height: 25px !important;

        &:nth-of-type(2) {
          display: none !important;
        }
      }

      &.menu-open span {
        &:nth-of-type(1) {
          display: none !important;
        }

        &:nth-of-type(2) {
          display: block !important;
          right: 20px !important;
        }
      }
    }

    &__items {
      display: none;
      position: absolute;
      opacity: 0;
      background-color: #061524;
      height: 100vh;
      top: 70px;
      left: 0;
      width: 100%;
      z-index: 99;
      padding: 55px 15px 60px 15px;
      //overflow: auto;
      transition: all .4s ease;
      overflow: auto;

      .mobile-menu__items__contact {
        height: 40px;
        width: 100%;
        border-radius: 24px;
        text-align: center;
        background-color: ${hover};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 70px;
        margin-top: 10px;

        p {
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          line-height: 24px;

        }


      }

      &__top {
        ul {
          li {
            a {
              height: 35px;
              border-radius: 18px;
              background-color: rgba(136, 136, 136, 0.5);
              color: #ffffff;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 30px;

              img {
                margin-right: 10px !important;
              }
            }

            &:nth-last-child(1) a {
              margin-bottom: 60px;
              background-color: ${hover};

              &:hover {
                color: #ffffff !important;
              }
            }
          }
        }
      }

      &__ul {
        li {
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          margin-bottom: 20px;

          &:nth-last-child(1) {
            border-bottom: 0;
          }

          a {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
          }

          &.active {
            border-color: ${hover};

            a {
              color: ${hover};
            }
          }
        }
      }
    }

    &.menu-open {
      .mobile-menu__items {
        display: block;
      }
    }

    //accordion
    .accordion-item {

      .accordion-header {
        .accordion-button {
          background-color: transparent;
          text-transform: capitalize;
          width: 100%;
          text-align: left;
          display: flex;
          justify-content: space-between;
          padding: 0;
          box-shadow: none;
          border: none;
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid ${hover};
          color: ${hover};
          font-weight: 500;
          font-size: 20px;
          line-height: 28px;

          &.collapsed {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
            color: #FFFFFF;
          }

          img {
            height: 20px;
            margin-top: 6px;

            &:nth-last-child(2) {
              display: none !important;
            }

            &:nth-last-child(1) {
              display: block !important;
            }
          }

          &.collapsed {
            img {
              &:nth-last-child(2) {
                display: block !important;
              }

              &:nth-last-child(1) {
                display: none !important;
              }
            }
          }

          &.collapsed {
            border-bottom: 1px solid #e1e4e6;
          }
        }

        &.active {
          .accordion-button {
            border-bottom: 1px solid rgb(25, 97, 172);
            color: rgb(25, 97, 172);
          }
        }
      }

      .accordion-body {
        padding-bottom: 25px;
        //padding-top: 20px;

        ul {
          li {
            border: none;
            padding: 0;
            margin: 0;
            font-size: 14px !important;
            line-height: 22px !important;


            a {
              font-size: 14px;
              line-height: 22px;
              font-weight: 400;
              color: #FFFFFF;
              padding: 20px 0;
              //margin-bottom: 20px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              display: block;

              svg {
                display: none;
              }

            }

            &:nth-of-type(1) a {
              padding-top: 0;
            }

            &:nth-last-of-type(1) a {
              border: none;
            }
          }
        }
      }

      &:nth-last-child(1) {
        .accordion-button {
          &.collapsed {
            border: none;
          }

        }
      }
    }
  }

  @media (min-width: 993px) {
    display: none;
  }

  @media (max-width: 993px) {
    &:before {
      content: "";
      position: absolute;
      //background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgb(0 0 0) 68%);
      background: linear-gradient(0deg, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 92%) 3%);
      height: 100%;
      width: 100%;
      left: 0px;
      top: 0px;
    }
  }


  .contact-info {
    position: fixed;
    height: 100vh;
    top: 70px;
    left: 0px;
    right: 0px;
    background-color: #061524;
    padding: 50px 15px 20px 15px;
    transform: translate3d(100vw, 0px, 0px);
    transition: all .4s ease;

    &__top {
      p {
        font-size: 20px;
        font-weight: 300;
        line-height: 28px;
        color: #FFFFFF;
        display: flex;
        margin-bottom: 60px;

        img {
          margin-right: 20px;

        }
      }

      a {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        color: #FFFFFF !important;
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        margin-bottom: 40px;
      }
    }


    &__desc {
      &__single {
        &:nth-of-type(1) {
          margin-bottom: 40px;
        }

        h4 {
          font-size: 12px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.5);
        }

        a {
          color: #FFFFFF !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          border: 0
        }
      }

    }
  }



`;
export default MainMenu;
