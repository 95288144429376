import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchBusiness} from "../../api/redux/Business";
import InnerBannerV2 from "../../components/InnerBannerV2";
import ZigZag from "../../components/domain/ZigZag";
import GetInTouch from "../../components/GetInTouch";
import {Loading} from "../../components/Loading";

const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.DOMAIN
        dispath(fetchBusiness([api_url]))
    }, [])



    let getPost = useSelector(state => state.business)


    //Refactor Data

    const banner = getPost?.posts?.page_data?.banner;
    const footerTop = getPost?.posts?.page_data?.footer_top;
    const data = getPost?.posts?.domain_list;
    const meta = getPost?.posts?.page_data?.meta;


    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'Business Domains'} | Infratech Consultants`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'Infratech Consultants specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
            </Helmet>


            <StyledComponent className={'business-domain-page'}>
                <InnerBannerV2 img={banner?.image} title={banner?.subtitle} text={banner?.title} mobile_img={banner?.mobile_image}/>
                {
                    data && data?.length > 0
                    && data.map((element, index) => {
                        if (index % 2 == 0) {
                            return (
                                <ZigZag key={index} title={element?.post_title} image={element?.detail_info?.image}
                                        background={'#E9E9E9'} item={element?.detail_info?.list} id={element?.post_name}/>
                            )
                        } else {
                            return (
                                <ZigZag key={index} title={element?.post_title} image={element?.detail_info?.image}
                                        background={'#FFFFFF'} item={element?.detail_info?.list} id={element?.post_name}
                                        reverse/>
                            )
                        }
                    })
                }
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
