import React, {useEffect} from 'react';
import styled from "styled-components";
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {fetchAbout} from "../../api/redux/About";
import Membership from "../../components/Membership";
import Footprint from "../../components/Footprint";
import Testimonial from "../../components/home/Testimonial";
import Customers from "../../components/about/Customers";
import Ministry from "../../components/about/Ministry";
import Management from "../../components/about/Management";
import TextWithLiner from "../../components/about/TextWithLiner";
import InnerBannerV2 from "../../components/InnerBannerV2";
import About from "../../components/About";
import GetInTouch from "../../components/GetInTouch";
import Awards from "../../components/about/Awards";
import StrengthV2 from "../../components/about/StrengthV2";


const MyComponent = () => {
    const dispath = useDispatch()

    useEffect(() => {
        window.scroll(0, 0)
    }, [])


    // api call
    useEffect(() => {
        let api_url = apiEndPoints.ABOUT
        dispath(fetchAbout([api_url]))

    }, [])

    let getPost = useSelector(state => state.about)

    // console.log(getPost);

    const banner = getPost?.posts?.page_data?.banner;
    const overview = getPost?.posts?.page_data?.overview;
    const strength = getPost?.posts?.page_data?.strength;
    const ministry = getPost?.posts?.page_data?.ministry;
    const management = getPost?.posts?.page_data?.management;
    const footprint = getPost?.posts?.page_data?.map;
    const mobileFootprint = getPost?.posts?.page_data?.mobile_map;
    const testimonial = getPost?.posts?.testimonial?.[0]?.detail_info;
    const customers = getPost?.posts?.customers?.[0]?.detail_info;
    const awards = getPost?.posts?.page_data?.awards;
    const licenses = getPost?.posts?.page_data?.licenses;
    const linerText = getPost?.posts?.page_data?.text;
    const footerTop = getPost?.posts?.page_data?.footer_top;
    const meta = getPost?.posts?.page_data?.meta;



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${meta?.meta_title ? meta?.meta_title : 'About'} | Infratech Consultants`}</title>
                <meta name="description" content={meta && meta.meta_desc ? meta.meta_desc
                    : 'Infratech Consultants specializes in designing & implementing hardware, software and other cutting-edge technological ' +
                    'solutions in the public infrastructure domain. We offer products and efficient customized solutions ' +
                    'through our wide network of partners, spread across the globe. With a vast experience in the public sector,' +
                    ' we ensure seamless implementation and execution of a diverse range of projects.'}/>
                {/*<link rel="canonical" href={banner?.image} />*/}

                {/*<meta property="og:image" content={banner?.image ? banner?.image : 'mus'}/>*/}
                {/*<meta itemProp="image"*/}
                {/*      content="https://azse77seaprodsa.blob.core.windows.net/b2b-dr-pickaboocdn/media/catalog/product/cache/90e3b9f4120fc209bf60003e3b0e1323/s/p/spark-10c-8-128-display-insurance.jpg">*/}
                {/*</meta>*/}

                {/*<meta property="og:image" content={banner?.image} />*/}

            </Helmet>


            <StyledComponent className={'customers-page'}>
                {/*{getPost?.loading && <Loading/>}*/}
                <InnerBannerV2 id={'nde'} img={banner?.image} mobile_img={banner?.mobile_image} title={banner?.subtitle} text={banner?.title}/>
                <About description={overview?.description} aboutPage data={overview}/>
                <StrengthV2 data={strength}/>
                {/*<Dojo id={'dojo'} data={strength}/>*/}
                <Management id={'management-team'} data={management}/>
                <Footprint id={'footprint'} img={footprint?.image} title={footprint?.title} data={mobileFootprint}/>
                <Testimonial id={'partners'} data={testimonial}/>
                <Ministry id={'ministry'} data={ministry}/>
                <Customers id={'customers'} data={customers}/>
                <TextWithLiner id={'textliner'} data={linerText}/>
                <Awards id={'accolades'} data={awards}/>
                <Membership id={'licenses-memberships'} data={licenses}/>
                <GetInTouch data={footerTop}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

  .customers {
    background: #FFFFFF !important;
  }
`;

export default MyComponent;
